.share {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
}

.top {
    display: flex;
    align-items: center;
    gap: 5px;
}

.wrapper {
    padding: 10px;
}

.profileImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 10px;
}

.shareInput {
    border: none;
    width: 80%;
}

.shareInput:focus {
    outline: none;
}

.shareHr {
    margin: 20px;
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shareOptions {
    display: flex;
    margin-left: 20px;
}

.option {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}

.shareIcon {
    font-size: 18px;
    margin-right: 3px;
}

.optionText {
    font-size: 14px;
}

.button {
    border: none;
    padding: 7px;
    border-radius: 5px;
    background-color: green;
    font-weight: 500;
    cursor: pointer;
    margin-right: 20px;
    color: white;
}
.post {
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    margin: 30px 0;
}

.wrapper {
    padding: 10px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topLeft {
    display: flex;
    align-items: center;
}

.profileImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.postUserName {
    font-size: 15px;
    font-weight: 500;
    margin: 0 10px;
}

.postDate {
    font-size: 12px;
}

.center {
    margin: 20px 0;
}

.postImg {
    margin-top: 20px;
    width: 100%;
    max-height: 500px;
    object-fit: contain;
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.postBottomLeft {
    display: flex;
    align-items: center;
}

.likeIcon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    cursor: pointer;
}

.postLikeCounter {
    font-size: 15px;
}

.postCommentText {
    cursor: pointer;
    border-bottom: 1px dashed gray;
    font-size: 15px;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,500&display=swap');

:root {
    --box-shadow: 7px -2px 23px 6px rgba(0, 0, 0, 0.15);
}

* {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(179, 179, 179);
}